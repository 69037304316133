<template>
    <div v-show="isShow">
        <van-popup v-model="isShow" position="bottom" round :style="'height: 50%;'" @close="handleClose" closeable>
            <div class="pay-details">
                <div class="title" v-if="!isPayCancel">请选择支付方式</div>
                <div class="title" v-else>请在<van-count-down :time="time" @finish="finish"/>分钟内支付</div>
                <div class="wechat-pay" @click="wechatPayClick">
                    <div class="row">
                        <img src="../../assets/images/wechat-pay.png" alt="" width="35" height="35">
                        <span>微信</span>
                    </div>
                    <van-checkbox v-model="wechatChecked" disabled></van-checkbox>
                </div>
                <div class="alipay" @click="alipayClick" v-show="!isWeiXin">
                    <div class="row">
                        <img src="../../assets/images/alipay.png" alt="" width="35" height="35">
                        <span>支付宝</span>
                    </div>
                    <van-checkbox v-model="alipayChecked" disabled></van-checkbox>
                </div>
                <div class="button-row">
                    <div class="button-buy" @click="submitOrder" >
                        <van-loading color="white" size="24px" v-show="isLoading"/>
                        <span v-show="!isLoading">{{ alipayChecked ? '支付宝' : '微信' }}付款</span>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Payment',
  components: {
  },
  activated() {
  },
  mounted(){
    this.isWeiXin = this.isWeiXinUA()
  },
  data(){
      return {
          isShow: false,
          alipayChecked: false,
          wechatChecked: true,
          isWeiXin: false,
          isLoading: false,
          isPayCancel: false,
          time: 0,
      }
  },
  methods:{
    show(){
        this.isShow = !this.isShow
    },
    wechatPayClick(){
        this.alipayChecked = false
        this.wechatChecked = true
    },
    // 设置倒计时time
    startCountDown(){
        let createAt = moment(this.createAt);
        let curDateTime = moment(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        let dura = curDateTime.format('x') - createAt.format('x');
        let tempTime = moment.duration(dura);
        this.time = 15 * 60 * 1000 - tempTime.minutes() * 60 * 1000 - tempTime.seconds() * 1000
    },
    showLoading(){
        this.isLoading = !this.isLoading
    },
    payCancelChange(){
        this.isPayCancel = !this.isPayCancel
    },
    alipayClick(){
        if(!this.isWeiXin){
            this.alipayChecked = true
            this.wechatChecked = false
        }
    },
    handleClose(){

    },
    isWeiXinUA() {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    submitOrder(){
        this.$emit('submitOrder')
    },
    finish(){
        this.showPopup = false
    }
  },
  props:{
    createAt:{
        type: String,
        default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.logout-dialog-box {
    width: calc(100% - 80px);
    max-width: 625px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;
    color: #000;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 1111;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pay-details {
    padding: 15px 20px 30px;
    background: #fff;
    .title {
        padding: 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .alipay {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        .row {
            display: flex;
            align-items: center;
            img {
                padding-right: 10px;
            }
        }
    }
    .wechat-pay {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        .row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
                padding-right: 10px;
            }
        }
    }
    .button-row {
        position: fixed;
        bottom: 10px;
        right: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 10px;
        padding-bottom: calc(-10px + constant(safe-area-inset-bottom));
        padding-bottom: calc(-10px + env(safe-area-inset-bottom));
        background: #fff;
        left: 0;
        .button-buy {
            width: 80%;
            height: 38px;
            display: flex;
            line-height: 30px;
            border-radius: 30px;
            align-items: center;
            font-size: 16px;
            color: #fff;
            background: #fc5f10;
            justify-content: center;
            border: 1px solid #fc5f10;
        }
    }
}
::v-deep .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: rgba(0,0,0,0.8);
    border-color: rgba(0,0,0,0.8);
}
::v-deep .van-count-down {
    color: #f0061d;
    font-size: 20px;
    line-height: 20px;
    padding: 0 10px;
}
</style>
