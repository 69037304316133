<template>
    <div class="order-details-box">
      <nav-index :title="'订单详情'"/>
      <div class="address-box">
        <div class="address" v-if="orderInfo.type === 'partner'" style="min-height: 0;">
          <div class="name-text">{{ orderInfo.nickname }} {{ orderInfo.phoneNumber }}</div>
        </div>
        <div class="address" v-else>
          <div class="name-text">{{ orderInfo.nickname }} {{ orderInfo.phoneNumber }}</div>
          <div class="address-text">{{ orderInfo.address }}</div>
        </div>
      </div>
      <order-card :orderInfo="orderInfo" :showButton="false" :showDetails="true" @goExpressPage="goExpressPage"/>
      <pay-footer :finalPayPrice="orderInfo.finalPayPrice" @payNow="openPayment" :status="orderInfo.status" 
          @deleteOrder="deleteOrderConfirm" @cancelOrder="cancelOrderConfirm" @expedited="expedited" :virtualOrder="isVirtualOrder"
          @received="receivedGoodsConfirm" @queryExpress="goExpressPage" @cancelRefund="cancelRefundConfirm"/>
      <confirm ref="confirm" @handleDelete="deleteOrder" @cancelOrder="cancelOrder"/>
      <confirm-received ref="confirmReceived" @handleReceived="receivedGoods"/>
      <confirm-refund ref="confirmRefund" @handleRefund="cancelRefund"/>
      <payment ref="payment" @submitOrder="submitOrder" :createAt="orderInfo.createAt"/>
    </div>
</template>

<script>
import OrderCard from '../../components/card/OrderCard.vue'
import PayFooter from '../../components/footer/Pay.vue'
import NavIndex from '../../components/nav/index.vue'
import { queryOrderById, deleteOrderByOid, updateOrderByOid, submitWechatPay, wxSdk } from '@/api/api'
import Confirm from '../../components/dialog/Confirm.vue'
import ConfirmReceived from '../../components/dialog/ConfirmReceived.vue'
import ConfirmRefund from '../../components/dialog/ConfirmRefund.vue'
import Payment from '../../components/dialog/Payment.vue'
import moment from 'moment'
import wx from "weixin-js-sdk";
import {Toast} from 'vant'
import { refundWechatPay } from '../../api/api'
const seo = require('../../../config/index')

export default {
  name: 'OrderDetails',
  components: {
    NavIndex,
    OrderCard,
    PayFooter,
    Confirm,
    Payment,
    ConfirmReceived,
    ConfirmRefund,
  },
  created(){
  },
  mounted(){
    this.getData()
    this.isWeiXin = this.isWeiXinUA()
  },
  data:()=>{
    return {
      orderInfo:{},
      isWeiXin: false,
      isVirtualOrder: false
    }
  },
  methods:{
    getData(){
      queryOrderById({ oid: this.$route.query.oid }).then(res=>{
        let resData = res.data.data
        let payment
        if(resData.payment === '1'){
          payment = '微信'
        } else if(resData.payment === '2'){
          payment = '支付宝'
        }
        this.isVirtualOrder = resData.type === 'partner' || resData.type === 'vip'
        this.orderInfo = {
            nickname: resData.nickname,
            phoneNumber: resData.phone_num,
            address: resData.address,
            lists: resData.lists,
            // imgUrl: resData.imgUrl,
            // title: resData.title,
            // type: resData.type,
            // amount: resData.amount,
            expressCharge: resData.parcel_fee,
            totalPrice: resData.total_price,
            discountPrice: resData.discount,
            finalPayPrice: resData.finally_price,
            createAt: resData.createdAt,
            payment: payment,
            oid: resData.oid,
            status: resData.order_status,
            waybill: resData.waybill,
            shop: resData.shop
        }
      })
    },
    openPayment(){
      this.$refs.payment.show()
    },
    submitOrder(){
        let params = {}
        params.oid = this.$route.query.oid
        params.price = this.orderInfo.finalPayPrice
        params.title = this.orderInfo.lists[0].title
        params.openid = localStorage.getItem('openid') ? localStorage.getItem('openid') : ''
        if(this.orderInfo.finalPayPrice > 0){
            this.$refs.payment.showLoading()
            if(this.isWeiXin){
                submitWechatPay(params).then(res=>{
                    this.wxSdk(res.data)
                })
            } else {
                Toast('请前往公众号支付');
                this.$refs.payment.showLoading()
            }
        }
    },
    wxSdk(payRes){
      let url = seo.config.homeUrl + this.$route.fullPath
          let my = this
          wxSdk({ url:url }).then(res=>{
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature,// 必填，签名
              jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
            });
            wx.ready(function() {
                /* 微信支付 */
                wx.chooseWXPay({
                    appId: payRes.appId,
                    timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
                    package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: payRes.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    paySign: payRes.paySign, // 支付签名
                    // 支付成功
                    success: function (res) {
                        console.log('支付成功', res)
                        if(res.errMsg === 'chooseWXPay:ok'){
                            let payment = '1' // 1-微信支付，2-支付宝支付
                            let payTime = moment(payRes.timeStamp).format("YYYY-MM-DD HH:mm:ss");
                            updateOrderByOid({ oid: my.$route.query.oid, order_status: 'orderPaid', payment: payment, pay_time:payTime }).then(()=>{
                                my.$router.push({ path: '/pay-result' })
                                my.$refs.payment.showLoading()
                            })
                        }
                    },
                    // 支付取消
                    cancel: function(res){
                        if(res.errMsg === 'chooseWXPay:cancel'){
                            Toast('支付已取消');
                            my.$refs.payment.startCountDown()
                            my.$refs.payment.payCancelChange()
                            my.$refs.payment.showLoading()
                        }
                    },
                    // 支付失败
                    fail: function(res){
                        console.log(res)
                        Toast('支付失败');
                        my.$refs.payment.showLoading()
                    }
                });
            })
        })
    },
    deleteOrderConfirm(){
      this.$refs.confirm.show('deleteOrder')
    },
    deleteOrder(){
      this.$refs.confirm.show('deleteOrder')
      deleteOrderByOid({ oid: this.$route.query.oid }).then(()=>{
        this.$router.push({path: '/order', query: { tabId: this.$route.query.tabId} })
      })
    },
    cancelOrderConfirm(){
      if(this.orderInfo.status === 'orderPicking' || this.orderInfo.status === 'orderPaid'){
        this.$refs.confirm.show('cancelOrderPaid')
      } else {
        this.$refs.confirm.show('cancelOrder')
      }
    },
    // 取消订单
    cancelOrder(type){
      this.$refs.confirm.show()
      if(type === 'cancelOrderPaid'){
        if(Number(this.orderInfo.finalPayPrice) < 20){
          let params = {}
          params.title = this.orderInfo.title
          params.out_trade_no = this.$route.query.oid // 订单号
          params.out_refund_no = moment().format('YYYYMMDDHHmmssSSS') // 退款单号，自行生成32字符串以内
          params.total_fee = this.orderInfo.finalPayPrice // 订单总金额
          params.refund_fee = this.orderInfo.finalPayPrice // 退款总金额
          // 已支付需要先微信退款
          refundWechatPay(params).then(res => {
            if(res.data.err_code && res.data.err_code === 'NOTENOUGH'){
              Toast('系统繁忙，请联系客服')
            } else {
              // 退款成功, 同步取消订单
              updateOrderByOid({ oid: this.orderInfo.oid, order_status: 'orderPaidCancel' }).then(()=>{
                this.getData()
              })
            }
          })
        } else {
            // 超过20元的订单退款需要审核
            updateOrderByOid({ oid: this.orderInfo.oid, order_status: 'orderApplyRefund' }).then(()=>{
              this.getData()
            })
        }
      } else {
        // type为cancelOrder直接取消
        updateOrderByOid({ oid: this.orderInfo.oid, order_status: 'orderCancel' }).then(()=>{
          this.getData()
        })
      }
    },
    // 催包裹
    expedited(){
      Toast('已提醒商家发货');
    },
    cancelRefundConfirm(){
      this.$refs.confirmRefund.show()
    },
    // 取消退款
    cancelRefund(){
      updateOrderByOid({ oid: this.$route.query.oid, order_status: 'orderPicking' }).then(()=>{
        this.getData()
      })
    },
    receivedGoodsConfirm(){
      this.$refs.confirmReceived.show()
    },
    // 确认收货
    receivedGoods(){
      updateOrderByOid({ oid: this.$route.query.oid, order_status: 'orderComment' }).then(()=>{
        this.getData()
      })
    },
    isWeiXinUA() {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    goExpressPage(oid){
      let oiiiid = oid ? oid : this.$route.query.oid
      let waybill = this.orderInfo.waybill ? this.orderInfo.waybill : ''
      let phoneNum = this.orderInfo.phoneNumber
      this.$router.push({ path:'/express', query:{ oid: oiiiid, waybill: waybill, phoneNum: phoneNum } })
    },
  }
}
</script>

<style scoped lang="scss">
.order-details-box {
  min-height: calc(100vh - 56px);
  padding-bottom: 56px;
  .address-box {
    padding-top: 50px;
    .address {
      margin: 10px 10px 0;
      padding: 10px 15px;
      background: #fff;
      text-align: left;
      font-size: 15px;
      border-radius: 15px;
      box-sizing: border-box;
      min-height: 75px;
      .name-text {
        margin: 5px 0;
      }
      .address-text {
        margin: 5px 0;
      }
    }
  }
}
</style>