<template>
    <div v-show="isShow">
        <div class="confirm-dialog-box">
            <div class="text">{{ text }}</div>
            <div class="confirm">
                <div class="cancel" @click="show">继续退</div>
                <div class="button" @click="handleRefund">
                    <span v-show="!isLoading">不退了</span>
                    <van-loading color="#ea6f7b" size="24px" v-show="isLoading"/>
                </div>
            </div>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'ConfirmRefund',
  components: {
  },
  data(){
      return {
          isShow: false,
          isLoading: false,
          text:'保留该订单，取消退款吗?',
      }
  },
  methods:{
        showLoading(){
            this.isLoading = !this.isLoading
        },
        show(){
            this.isShow = !this.isShow
        },
        handleRefund(){
            this.$emit('handleRefund')
            this.isShow = !this.isShow
        },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.confirm-dialog-box {
    width: 80%;
    max-width: 625px;
    line-height: 50px;
    margin: 0 auto;
    color: #000;
    background: #FFF;
    max-width: 625px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    position: fixed;
    border-radius: 5px;
    top: 40%;
    z-index: 999;
    .text {
        font-size: 16px;
        padding: 20px 0;
        border-bottom: 1px solid #cdcdcd;
    }
    .confirm {
        display: flex;
        .button {
            width: 50%;
            font-size: 18px;
            color: #eb411f;
        }
        .cancel {
            width: 50%;
            font-size: 18px;
            color: #969696;
            border-right: 1px solid #cdcdcd;
        }
    }
}
.van-loading {
    position: relative !important;
    left: 0;
    right: 0;
    top: 2px;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
</style>
